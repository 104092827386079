import Vue from 'vue'
import App from './App.vue'
import vuetify from '../plugins/vuetify'
import router from './router'
import firebase from "firebase/app";
import 'firebase/firestore';
import axios from 'axios'
import VueAxios from 'vue-axios'
import AxiosPlugin from 'vue-axios-cors'
import VueGoogleCharts from 'vue-google-charts'
import 'firebase/firebase-messaging'


Vue.prototype.$http = AxiosPlugin;
Vue.config.productionTip = false;
Vue.use(VueGoogleCharts)
Vue.use(VueAxios, axios);
Vue.use(AxiosPlugin);

export const bus = new Vue();

firebase.initializeApp({
        apiKey: "AIzaSyAbKeIOiuumM2AYsvPgJuyytH0CvIwxhsU",
        authDomain: "tringpartner-v2.firebaseapp.com",
        databaseURL: "https://tringpartner-v2-default-rtdb.firebaseio.com",
        projectId: "tringpartner-v2",
        storageBucket: "tringpartner-v2.appspot.com",
        messagingSenderId: "448716065880",
        appId: "1:448716065880:web:b666bc301f6a0e563b4e2d",
        measurementId: "G-BNC0CHMT4E"
});

export const db = firebase.firestore();
const messaging = firebase.messaging();
messaging.requestPermission()
    .then(() => {
        console.log("have perm")
        return messaging.getToken({ vapidKey: "BJw0IaMJiCU895t7oCswxP06NhxLlfV39whGDSelluOBGzextq0WJ1oeaVQwc8gvVFh5HMXzshm_0QCIcJU_TNU" })
    })
    .then(token => {
        console.log("token", token)
        localStorage.setItem("webtoken", token);
    }).catch((err) => { console.log("1", err) })

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')

import '/src/assets/css/main.css';

Vue.prototype.$mongoApi = 'http://35.244.46.144:3000';
Vue.prototype.$headerKeyMongo = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWMyZmEyMjUxNmFhMzBlZDg1ZDM3NjgiLCJpYXQiOjE2NDAxNjgwMjl9._bnmHqiumqulAG6KJl45n0fGc__w4nxoy-Nsy2s5PU8';
Vue.prototype.$cloudfareApi = 'https://asia-south1-tringpartner-v2.cloudfunctions.net/tpv2/web';
Vue.prototype.$razorpaykey = "rzp_live_exQtzNvlflkLn7"
